import { Outlet, NavLink, Link } from "react-router-dom";
import "../style/nav.scss"
const Layout = () => {
    return (
        <>
            <nav id="mainnav">
                <ul>
                    <li>
                        <NavLink to="/">Home</NavLink>
                    </li>
                    <li>
                        <NavLink to="/blogs">Blogs</NavLink>
                    </li>
                    <li>
                        <NavLink to="/contact">Contact</NavLink>
                    </li> 
                    <li><NavLink to="/OCE">OCE</NavLink></li>
                    <li><NavLink to="/CF">CF</NavLink></li>
                    <li><NavLink to="/TaskView">TaskView</NavLink></li>
                </ul>
            </nav>

            <Outlet />
        </>
    )
};

export default Layout;