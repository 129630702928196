import "../style/landingpage.scss"
import "../style/cf.scss" 
import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import CFPCI from '../layout/CF_PCI';
import '../style/cf.scss';

const CFpage = () => {
  const rankcolor = {
    'Newbie': 'gray', 'Pupil': 'green', 'Specialist': 'cyan', 'Expert': 'blue', 'Candidate Master': 'violet',
    'Master': 'orange', 'International Master': 'orange', 'Grandmaster': 'red', 'International Grandmaster': 'red',
    'Legendary Grandmaster': 'bred'
  };

  const handleList = ['MrA', 'mastercopycode', 'tank_20Ti', 'Ti20_dtlong', 'ti20_hndkhoa', 'ti20_13minusone',
                      'ti20_lnakhoa', 'ti20_ntson', '20Tithaison', 'tourist', 'maroonrk', 'Benq', 'Petr'];

  const [handle, setHandle] = useState('');
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [personInF, setPersonInF] = useState([]);
  const [cfinf, setCfinf] = useState([]);
  const [steps, setSteps] = useState(0);

  useEffect(() => {
    // ComponentDidMount logic
    // If you want to fetch data on initial load, uncomment below
    // getdata();
    return () => {
      // componentWillUnmount logic
      // Cleanup if needed
    };
  }, []); // Empty array as second argument means this effect runs once on mount

  const handleChange = (event) => {
    setHandle(event.target.value);
  };

  const btnclick = (event) => {
    event.preventDefault();
    const val = event.target.value;
    setIsLoaded(false);
    setHandle(val);
    setApicall(`https://codeforces.com/api/user.info?handles=${val}`);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const handleval = document.getElementById('txthandle').value;
    if (handleval !== '') {
      setIsLoaded(false);
      setHandle(handleval);
      setApicall(`https://codeforces.com/api/user.info?handles=${handleval}`);
    }
  };

  const getdata = () => {
    if (isLoaded) return;
    const cancelTokenSource = axios.CancelToken.source();
    axios.get(apicall, { cancelToken: cancelTokenSource.token })
      .then(res => {
        cancelTokenSource.cancel();
        const status = res.data.status === "OK";
        if (status) {
          const items = res.data.result;
          items.forEach(person => {
            const unixTime = parseInt(person.lastOnlineTimeSeconds);
            const date = new Date(unixTime * 1000);
            const datestr = date.toLocaleDateString("en-US");
            setPersonInF([person.handle, datestr, person.rating, person.maxRating, person.rank, person.maxRank, person.titlePhoto]);
            setIsLoaded(true);
            // setCfinf([<CFPCI handle={handle} rating={person.rating} />]);
            setSteps(steps + 1);
            toogleform(false);
          });
        }
      })
      .catch(error => {
        console.error(`${apicall} => ${error}`);
      });
    toogleform(false);
  };

  const [apicall, setApicall] = useState(`https://codeforces.com/api/user.info?handles=${handle}`);

  const toogleform = (flag) => {
    document.getElementById('txthandle').hidden = flag;
    document.getElementById('btnHandlesubmit').hidden = flag;
  };

  const getrankcolor = (val) => {
    if (!val) return 'default';
    for (const key in rankcolor) {
      if (val.toUpperCase() === key.toUpperCase()) {
        return rankcolor[key];
      }
    }
    return 'default';
  };

  let htmlitems = [];
  let handles = [];
  for (let i = 0; i < handleList.length; i++) {
    handles.push(<button id={handleList[i]} value={handleList[i]} onClick={btnclick}>{handleList[i]}</button>);
  }

  let htmlitems1 = (
    <form onSubmit={handleSubmit}>
      <label>
        Handle:
        <input id='txthandle' type="text" onChange={handleChange} />
      </label>
      <input id='btnHandlesubmit' type="submit" value="View" />
    </form>
  );

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    htmlitems.push(
      <div>
        <div id='mainContainerCF'>
          {htmlitems1}
          {handles}
        </div>
        Loading...
      </div>
    );
    return htmlitems;
  } else {
    let htmlitems2 = <div id="CFHeader"><b>{handle}</b></div>;
    let rankdelta = personInF[2] - personInF[3];
    let rankdeltacolor = rankdelta < 0 ? "clred" : "clgreen";
    let rankcurcolor = getrankcolor(personInF[4]);
    let rankmaxcolor = getrankcolor(personInF[5]);
    let htmlitem3 = (
      <div>
        <div id='personalInf'>
          <span>Last see online: {personInF[1]}</span>
          <span>Rating (Cur/Max): <b className={rankcurcolor}>{personInF[2]}</b>/<b className={rankmaxcolor}>{personInF[3]}</b> (<b className={rankdeltacolor}>{rankdelta}</b>)</span>
          <span>Rank (Cur/Max): <b className={rankcurcolor}>{personInF[4]}</b>/<b className={rankmaxcolor}>{personInF[5]}</b></span>
        </div>
      </div>
    );

    htmlitems.push(
      <div id='mainContainerCF'>
        {htmlitems1}
        {handles}
        {htmlitems2}
        {htmlitem3}
        {cfinf}
      </div>
    );
    toogleform(false);
    return htmlitems;
  }
};

export default CFpage;
