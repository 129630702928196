
import "../style/landingpage.scss"
const Home = () => {
    return <div className="container-fluid">
        <div id="landingimg"><img id="" src="https://res.cloudinary.com/mrcat/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1704194379/programvn/img/CPP_background_ywuchn.jpg" width="50%"></img>
        </div>
        <div id="homelink"><a href="https://program.edu.vn">ProgramVN</a></div>
    </div>;
};

export default Home;